/*
// .block-product-columns
*/
@import '../variables';
@import '../functions';
@import '../mixins/product-card';


@media (max-width: 479px){

    .infinityscroll .products-list[data-layout="grid-5-full"] .products-list__item {
        /* width: 100%; */
        width: calc(50% - 12px)  !important;
        margin: 6px 6px;
    }


   // .products-list[data-layout="grid-5-full"] .products-list__item
}

