/*
// .card
*/
@import '../variables';
@import '../functions';


.card {
    border: $card-border;
    border-radius: $card-border-radius;
}
.card-body {
    padding: 0.5rem;
}
.card-header,
.card-footer {
    padding: 1.5rem 2rem;
    border-top: none;
    border-bottom: none;
    background-color: transparent;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }
}
.card-title {
    margin-bottom: 0.75rem;
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 1rem;
    color: $bg-red-color !important;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
        font-size: 1rem;
    }
}
.card-divider {
    height: 2px;
    background: $card-border-color;
}
.card-body .card-title:last-child {
    margin-bottom: 0;
}
.card-columns {
    column-count: 8 !important;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.card-text {
    font-size: 0.9rem;
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .card-header,
    .card-footer {
        padding: 1.25rem 1.5rem;
    }
    .card-title {
        font-size: 1rem;
        margin-bottom: 0.75rem;
    }
    .card-body {
        padding: 0.5rem;
    }
    .card-columns {
        column-count: 5 !important;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .card-header,
    .card-footer {
        padding: 1rem 1.375rem;
    }
    .card-title {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
    .card-body {
        padding: 0.5rem;
    }
    .card-columns {
        column-count: 2 !important;
    }
}


