// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
//@import 'themes/red';
//@import 'themes/blue';
//@import 'themes/black';
//@import 'themes/green';
@import 'themes/custom';
$footer-bg:                          #b40101                    !default;
$footer-text:                        #FFFFFF                    !default;
$footer-title:                       #FFCC00                    !default;
$nav-links-item-hover-bg:            #b40101                    !default;
$body-font-family: 'Open Sans', 'sans-serif';
$price-new-color:                    #b40101                    !default;
$bg-red-color:                       #b40101                    !default;
$text-color:                         #FFFFFF                    !default;
$product-card-quickview-color:        #b40101                    !default;
$product-card-bg:                    #cccccc                    !default;
$indicator-default-counter-bg:       #b40101                    !default;
$indicator-default-counter-font-color:#FFFFFF                    !default;
$indicator-hover-counter-bg:      #b40101                    !default;
$indicator-hover-counter-font-color:#FFFFFF                    !default;
