/*
// .block-brands
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';


.block-brands {}
.block-brands__slider {
    //@include card();
    //border: 2px solid #f0f0f0;
    //border-radius: 2px;
}
.block-brands__item {
    // padding: 25px;
    vertical-align: middle;
    border: 2px solid #B40101;
    margin-right: 5px;
    margin-top: 5px;
    text-align: center;
    color: #B40101;
    font-size: 13px;
    text-transform: uppercase;
    background-color: rgba(153, 153, 153, .3);
    border-radius: 15px;
   // opacity: 0.5;
    img {
        max-width: 100%;
        height: 100px;
        text-align: center;
        margin: auto;
    }
    &:hover {
        background-color: rgba(180, 1, 1, 1);
        color: #FFF;
        cursor: pointer;
        //opacity: 1;
    }
}

.slick-prev {
   
    left: -35px;
    top: 35%;
    position: absolute;
    z-index: 1;
    font-size: 30px;
    color: #B40101;
}
.slick-next {
    right: -35px;
    top: 35%;
    position: absolute;
    z-index: 1;
    font-size: 30px;
    color: #B40101;
}

@media (max-width: breakpoint(sm-end)) {
    .block-brands__item {
        padding: 20px 24px;
    }
}
