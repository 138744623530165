// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

//$footer-bg:                          #b40101                    !default;
@import './variables';
@import './functions';

.bg-red-color {
    background-color: $bg-red-color;
}     

.card{
    cursor: pointer;
    border: solid 1px #aaa;
    justify-content: center;
    position: relative;
    display: inline-block;
}

.card:hover{
    cursor: pointer;
    border: solid 1px $footer-bg;

}

.brandColumns .card:hover{
    background: $bg-red-color;
    box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
}

.card .img-top {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.card:hover .img-top {
    display: inline;
}

@media (min-width: breakpoint(xs-start)) and (max-width: breakpoint(xs-end)) {
    .brandColumns .card{
        margin-left: 4px !important;
    }

    .modelColumns .card{
        margin-left: 8px !important;
    }
    
}

.mainImg{
    //background: url('images/categories/parts/main/a.jpg');
    // width: 100px;
    // height: auto;
    // background: ur('images/categories/parts/main/a.jpg') 50% 50% no-repeat;
    text-align: center;
}

.text-red-color {
    color: $bg-red-color;
} 

.tab-active, .tab-panel-active{
    background: $bg-red-color !important;
    border-color: #aaa;
    color: #fff;
    border-radius: 5px 5px 0 0;
}

.categories-title{
    text-align: center;
    font-size: 14px !important;
    margin: 10px 0px;
    color: $bg-red-color;
    font-weight: 400;
}

.react-tabs__tab{
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    margin: 2px 3px 2px 3px;
    background: #e1e1e1;
}

.modelColumns{
    a:hover {
            color:$bg-red-color !important;
    }

}

.category-card{

    
    .card-header {
        padding: .5rem !important;
        margin-bottom: 0 !important;
        background-color: rgba(0,0,0,.03) !important;
        border-bottom: 1px solid rgba(0,0,0,.125) !important;
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0 !important;
    }
	a:hover {
			color:$bg-red-color !important;
	}

    .card-body {
        padding: 0.5rem !important;
    }

    .nostyle{
        padding: 0;
        margin: 0 auto 15px;
        list-style: none;
		font-size: 14px;
    }

    .card-footer:last-child {
        border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
    }
    .card-footer {
        padding: 0.5rem !important;
        border-top: none;
        border-bottom: none;
        background-color: transparent;
    }

    .fa-cog{
        color: $bg-red-color
    }
	.card-title {
		margin-bottom: 0;

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 0;
		}
		a, a:hover {
			color:$bg-red-color !important;
			font-size: 16px;
		}
		
	}

    @media (min-width: breakpoint(xs-start)) and (max-width: breakpoint(xs-end)) {
        .card{
            width: 46% !important;
            margin: 5px !important;
        }
        .card-columns {
            column-count: 2 !important;
        }
    }

    @media (min-width: breakpoint(sm-start)) and (max-width: breakpoint(sm-end)) {
        .card{
            width: 46% !important;
            margin: 5px !important;
        }
        .card-columns {
            column-count: 2 !important;
        }
    }

    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .card{
            width: 18% !important;
            margin: 8px !important;
        }

        .card-columns {
            column-count: 4 !important;
        }
    }

    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .card{
            width: 18% !important;
            margin: 8px !important;
        }
        .card-columns {
            column-count: 4 !important;
        }
    }

   

}