/*
// base
*/
@import '../variables';
@import '../mixins/direction';


html {
    height: 100%;
}


body {
    height: 100%;
    font-family: $body-font-family;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    background: $body-bg;
    color: $body-font-color;
    backface-visibility: hidden;
    overflow-y: scroll;

    @include direction {
        text-align: $inline-start;
        direction: $direction;
    }
}


#root {
    height: 100%;
}


svg {
    display: block;
}


a {
    color: $link-color;
}
a:hover {
    color: $link-hover-color;
    text-decoration: none;
}


label {
    margin-bottom: .25rem;
}


h1 {
    @if ($h1-font-family != null) { font-family: $h1-font-family; }
    @if ($h1-font-size != null)   { font-size: $h1-font-size; }
    @if ($h1-font-weight != null) { font-weight: $h1-font-weight; }
    @if ($h1-font-style != null)  { font-style: $h1-font-style; }
}
h2 {
    @if ($h2-font-family != null) { font-family: $h2-font-family; }
    @if ($h2-font-size != null)   { font-size: $h2-font-size; }
    @if ($h2-font-weight != null) { font-weight: $h2-font-weight; }
    @if ($h2-font-style != null)  { font-style: $h2-font-style; }
}
h3 {
    @if ($h3-font-family != null) { font-family: $h3-font-family; }
    @if ($h3-font-size != null)   { font-size: $h3-font-size; }
    @if ($h3-font-weight != null) { font-weight: $h3-font-weight; }
    @if ($h3-font-style != null)  { font-style: $h3-font-style; }
}
h4 {
    @if ($h4-font-family != null) { font-family: $h4-font-family; }
    @if ($h4-font-size != null)   { font-size: $h4-font-size; }
    @if ($h4-font-weight != null) { font-weight: $h4-font-weight; }
    @if ($h4-font-style != null)  { font-style: $h4-font-style; }
}
h5 {
    @if ($h5-font-family != null) { font-family: $h5-font-family; }
    @if ($h5-font-size != null)   { font-size: $h5-font-size; }
    @if ($h5-font-weight != null) { font-weight: $h5-font-weight; }
    @if ($h5-font-style != null)  { font-style: $h5-font-style; }
}
h6 {
    @if ($h6-font-family != null) { font-family: $h6-font-family; }
    @if ($h6-font-size != null)   { font-size: $h6-font-size; }
    @if ($h6-font-weight != null) { font-weight: $h6-font-weight; }
    @if ($h6-font-style != null)  { font-style: $h6-font-style; }
}

.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	
	font-size: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 18px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
    padding: 20px;
    font-size: 20px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

iframe {
    display: none !important;
}

@media (max-width: 480px){
    ._2qp0Z {
        margin-right: 80px !important;
    }
}

