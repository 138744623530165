/*
// .products-list
*/
@import '../variables';
@import '../functions';
@import '../mixins/product-card';


@mixin local-layout($columns: 1, $margin-vertical: 0, $margin-horizontal: 0) {
    .products-list__body {
        margin: #{-$margin-vertical} #{-$margin-horizontal};
    }
    .products-list__item {
        @if ($columns == 1) {
            width: 100%;
        } @else {
            width: calc(#{100% / $columns} - #{$margin-horizontal*2});
        }
        margin: $margin-vertical $margin-horizontal;
    }
}


.products-list {}
.products-list__body {
    display: flex;
    flex-wrap: wrap;
}
.products-list__item {
    display: flex;

    .product-card {
        width: 100%;
        flex-shrink: 0;
    }
}



// Grid
.products-list[data-layout^="grid-"] {
    .product-card {
        @include product-card-grid();
    }

    &[data-with-features="true"] .product-card {
        @include product-card-grid-with-features();
    }
}
.products-list[data-layout="grid-3-sidebar"] {
    @include local-layout(3, 6px, 6px);

    .product-card {
        @include product-card-grid-nl();
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(1, 6px, 6px);
    }
}
.products-list[data-layout="grid-4-full"] {
    @include local-layout(4, 6px, 6px);

    .product-card {
        @include product-card-grid-nl();
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(2, 6px, 6px);
    }
}
.products-list[data-layout="grid-5-full"] {
    @include local-layout(5, 6px, 6px);

    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
        @include local-layout(4, 6px, 6px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(2, 6px, 6px);
    }
}




.products-list[data-layout="list"] {
    @include local-layout(1, 6px);

    .products-list__item {
        display: block;
    }

    .product-card {
        @include product-card-list();
    }
}
